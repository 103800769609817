<template>
  <!-- <b-overlay
    :show="show"
    variant="transparent"
    opacity="0"
    blur="5px"
    rounded="lg"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template> -->
  <div>
    <br><br><br><br><br>
    <div class="container-sm fn text-white">
      <div>
        <router-link
          :to="{ name: 'home' }"
          style="color: #000"
        >
          <div class="d-flex align-items-center">
            <div class="box-back text-center mr-1">
              <i class="fas fa-long-arrow-left" />
            </div>
            <h5 class="text-center font-weight-bolder m-0">
              ฝากประจำรับโบนัส
            </h5>
          </div>
        </router-link>

        <div class="p-1">
          <b-row class="cs mb-2">
            <b-col cols="12">
              <div>
                <h5 class="text-center mb-1">
                  <u> เงื่อนไขกิจกรรม </u>
                </h5>
                <p style="color: #fff">
                  <i
                    class="fad fa-coins"
                    style="color: #fff"
                  /> ฝากติดต่อกัน 7
                  วัน รับ 200 บาท
                </p>
                <p style="color: #fff">
                  <i
                    class="fad fa-coins"
                    style="color: #fff"
                  /> ฝากติดต่อกัน 14
                  วัน รับ 500 บาท
                </p>
                <div class="text-center">
                  <small
                    class="text-danger"
                  >***ฝากแรกของวันละ 500 บาท ( รับเพื่อรับโบนัส )***</small>
                </div>
              </div>
            </b-col>
            <!-- <b-col
            cols="12"
            class="mt-2"
          >
            <b-calendar
              selected-variant="warning"
              hide-header="hideHeader"
              :show-decade-nav="hideDecadeNav"
              block
              locale="th-TH"
            />
          </b-col> -->
          </b-row>
          <div class="row1">
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 1"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p
                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    1
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p
                    class="mb-0"
                  >
                    วันที่<br>
                    1
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 2"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    2
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p
                    class="mb-0"
                  >
                    วันที่<br>
                    2
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 3"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    3
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    3
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 4"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    4
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">

                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    4
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 5"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p
                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    5
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">

                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    5
                  </p>
                </div>
              </div>
            </div>
            <div class="column2">
              <small style="color: #fff">&nbsp;</small>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 6"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    6
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    6
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div class="card1">
                <b-img
                  class="icon-img2"
                  src="/Low/fs2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p
                    v-if="User.deposit_day_count >= 7"
                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    7
                  </p>
                  <p
                    v-else
                    class="mb-0"
                  >
                    วันที่<br>
                    7
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 8"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    8
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    8
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 9"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    9
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    9
                  </p>
                </div>
              </div>
            </div>
            <div class="column2">
              <small style="color: #fff">&nbsp;</small>
            </div>
            <div class="column12">
              <small style="color: #fff">&nbsp;</small>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 10"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="label-box">
                  <p

                    class="mb-0 text-center"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    10
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="label-box">
                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    10
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 11"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="label-box">
                  <p

                    class="mb-0 text-center"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    11
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="label-box">
                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    11
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 12"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="label-box">
                  <p

                    class="mb-0 text-center"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    12
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="label-box">
                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    12
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <small style="color: #fff">&nbsp;</small>
            </div>
            <div class="column3">
              <small style="color: #fff">&nbsp;</small>
            </div>
            <div class="column12">
              <div
                v-if="User.deposit_day_count >= 13"
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix_2.svg"
                  width="62"
                />
                <div class="label-box">
                  <p

                    class="mb-0 text-center"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    13
                  </p>
                </div>
              </div>

              <div
                v-else
                class="card1"
              >
                <b-img
                  class="icon-img2"
                  src="/Low/fix.svg"
                  width="62"
                />
                <div class="label-box">
                  <p

                    class="mb-0"
                  >
                    วันที่<br>
                    13
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <div class="card1">
                <b-img
                  class="icon-img2"
                  src="/Low/fs2.svg"
                  width="62"
                />
                <div class="text-center label-box">
                  <p
                    v-if="User.deposit_day_count >= 14"
                    class="mb-0"
                    style="color: #ffc800"
                  >
                    วันที่<br>
                    14
                  </p>
                  <p
                    v-else
                    class="mb-0"
                  >
                    วันที่<br>
                    14
                  </p>
                </div>
              </div>
            </div>
            <div class="column12">
              <small style="color: #fff">&nbsp;</small>
            </div>
          </div>
        </div>
        <hr>

        <div class="text-center">
          <button
            class="btn-theme-two btn btn-block"
            @click="getbonus"
          >
            กดรับโบนัส
          </button>
        </div>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>
  </div>
  <!-- </b-overlay> -->
</template>

<script>
import { BCol, BImg, BRow } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    // BCalendar,
    BImg,
    // BOverlay,
    // BIconController,
  },
  data() {
    return {
      show: true,
      selected: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
    }
  },
  mounted() {
    this.getUser(this.userData)
  },
  methods: {
    getUser(userData) {
      this.$http
        .get(`/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    getbonus() {
      // console.log(this.User.deposit_day_count)
      this.show = true
      if (this.User.deposit_day_count >= 7) {
        this.$http
          .get('/promotion/getfixbonus')
          .then(response => {
            this.show = false
            this.Success(response.data.message)
            this.getUser(this.userData)
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.show = false
        this.SwalError(
          'ไม่สามารถรับโบนัสได้ เนื่องจากท่านฝากประจำยังไม่ครบตามที่กำหนดไว้',
        )
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
.btnlogout {
  padding: 0.75em;
    background: linear-gradient(180deg, #5a499d, #bd6aaa) !important;
    color: #FFF;
    border-radius: 15px;
    width: 100%;
    height: 43px;
    border: none;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    text-align: center;
}
.all-title-head {
  text-align: center;
  background: linear-gradient(to bottom, #393939, #000000);
  margin-top: 30px;
  padding: 10px 0;
  border-radius: 4px;
  text-align: left;
  color: #ebc76e;
  font-weight: 300;
  display: flex;
  justify-content: center;
}
.dtx2 {
  position: relative;
  display: flex;
  margin-left: 5px;
  margin-top: -38px;
  justify-content: left;
  color: #000;
  font-weight: 600;
  align-items: left;
}
.container {
  max-width: 540px;
}
/* .icon-img2{
  width: 82px;
  height: 82px;
} */
.label-box {
  text-align: center;
  /* position: absolute !important; */
  margin-top: -40px !important;
  /* margin-left: 12px !important; */
  font-weight: 700 !important;
  color: #fff !important;
}
.balance-cl {
  background-color: #ffc800;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.column12 {
  text-align: center;
  float: left;
  width: 20%;
  height: 60px;
  font-size: 16px;
  color: #fff;
  padding: 0;
}
.column2 {
  float: left;
  width: 10%;
  height: 60px;
  font-size: 16px;
  color: #fff;
  padding: 0;
}
.column3 {
  float: left;
  width: 30%;
  height: 60px;
  font-size: 16px;
  color: #fff;
  padding: 0;
}

.row1 {
  margin: 0 -5px;
}

.row1:after {
  content: '';
  display: table;
  clear: both;
}
a {
  color: #fff;
}
p {
  font-size: 12px;
  color: #fff;
  line-height: 1.4;
  font-weight: 300;
}
.cs {
  background: rgba(0, 0, 0, 0.336);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: #2a2a2b94 0px 5px 10px 5px;
}
/* asdasd */
</style>
